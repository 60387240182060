<template>
  <div>
    <!-- Filters -->
    <order-promotion-filter
      :discount-filter="disCountType"
      :search-query.sync="searchQuery"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{$t('Show')}}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{$t('entries')}}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <crud-button :onClickRefresh="refetchData" :onClickAddNew="addNewProm" />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        small
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Date -->
        <template #cell(dtStart)="data">
          {{ formatDateTime(data.item.dtStart) }}
        </template>
         <template #cell(dtExpired)="data">
          {{ formatDateTime(data.item.dtExpired) }}
        </template>

        <!-- Column: Status -->
        <template #cell(orderPromotionDiscountType)="data">
          <div class="text-nowrap">
            <b-badge
              pill
              variant="info"
              v-show="data.item.orderPromotionDiscountType === 1"
            >
              <feather-icon
                icon="PercentIcon"
                size="18"
                class="mr-50 text-white"
              />
              <span class="align-text-top text-capitalize">PERCENT</span>
            </b-badge>
            <b-badge
              pill
              variant="success"
              v-show="data.item.orderPromotionDiscountType === 2"
            >
              <feather-icon
                icon="UnderlineIcon"
                size="18"
                class="mr-50 text-white"
              />
              <span class="align-text-top text-capitalize">UNIT</span>
            </b-badge>
          </div>
        </template>
        
        <template #cell(orderPromotionType)="data">
          <div class="text-nowrap">
            <b-badge
              pill
              variant="info"
              v-show="data.item.orderPromotionType === 1"
            >
              <feather-icon
                icon="UsersIcon"
                size="18"
                class="mr-50 text-white"
              />
              <span class="align-text-top text-capitalize">GENERAL</span>
            </b-badge>
            <b-badge
              pill
              variant="success"
              v-show="data.item.orderPromotionType === 2"
            >
              <feather-icon
                icon="UserIcon"
                size="18"
                class="mr-50 text-white"
              />
              <span class="align-text-top text-capitalize">CUSTOMER</span>
            </b-badge>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <!-- Update -->
          <feather-icon
            @click="$router.push({ name: 'manage-order-promotion-save', params: { id: data.item.id } })"
            icon="EditIcon"
            class="cursor-pointer text-primary"
            size="16"
          />

          <!-- Remove -->
          <feather-icon
            @click="deleteProm(data.item.id)"
            icon="Trash2Icon"
            class="cursor-pointer mx-1 text-danger"
            size="16"
          />
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { formatDateTime } from '@core/utils/filter'

// Alert Toast
import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

import OrderPromotionFilter from './OrderPromotionFilter.vue'
import useArchivedOrderPromotion from './useArchivedOrderPromotion'

export default {
  components: {
    OrderPromotionFilter,
    vSelect,
  },
  methods: {
    addNewProm() {
      this.$router.push({ name: 'manage-order-promotion-save', params: { id: 0 } });
    },
    deleteProm(id) {
      AlertService.confirmDelete(this, () => {
        this.$store.dispatch('orderPromotionManage/remove', { id })
          .then(response => {
            if (response.statusCode === 200) {
              this.$toast({
                component: ToastificationSuccess,
                position: 'top-right',
                props: { text: this.$t(response.message) },
              })
              this.refetchData()
            } else {
              AlertService.error(this, this.$t(response.message))
            }
          });
      });
    },
    formatDateTime,
  },
  setup() {
    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      disCountType,
      isValids,
      refListTable,

      refetchData,
    } = useArchivedOrderPromotion()

    return {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      disCountType,
      isValids,
      refListTable,

      refetchData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
